@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../styles/settings';
@import '../../../node_modules/bootstrap/scss/mixins/grid';
@import '../../../node_modules/bootstrap/scss/mixins/breakpoints';

.Modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1000;
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 1000;
  }
  &__frame {
    position: absolute;
    z-index: 1010;
    border: 1px solid #ddd;
    background: #fff;
    border-radius: 10px;
    max-height: 97vh;
    max-height: calc(100vh - 40px);
    width: 92%;
    overflow: auto;

    &--top {
      top: 10px;
      left: 50%;
      transform: translateX(-50%);
    }
    &--center {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &__close {
    display: inline-block;
    position: absolute;
    top: rem(20);
    font-size: 0.85rem;
    color: $gray-700;
    cursor: pointer;
    z-index: 1020;
    right: rem(20);
  }
  &__content {
    /* cover the modal */
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    /* spacing as needed */
    padding: rem(35) rem(10);

    /* let it scroll */
    // overflow: auto;
  }
}
