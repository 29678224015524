@import '../../styles/settings';

.ItemSummary {
  display: flex;
  flex-direction: column;
  &__item {
    margin-bottom: 8px;
    font-size: rem(28) !important;
    font-weight: 700;
  }
  &__count {
    font-size: 14px;
    color: $gray-700;
    font-weight: 500;
  }
}
