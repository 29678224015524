@import '../../styles/settings';

.form {
    background-color: $gray-100;
    min-height: 100vh;
    display: inline-block;
    width: 100%;

    h6 {
        margin-bottom: rem(36);
        text-align: center;
    }

    &__inner {
        max-width: 573px;
        margin: 4.688rem auto;
        padding: 0 0.9375rem;
        font-weight: 500;
    }

    .logo {
        text-align: center;
        margin-bottom: 1.875rem;
    }

    .button-outer {
        padding: 0.75rem 0;
    }

    p {
        color: $gray-700;
    }

    &.forgot {
        p {
            padding-bottom: 2rem;
        }

        span {
            font-size: 0.875rem;
            color: $gray-400;
        }
    }
}
