@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../styles/settings';
@import '../../../node_modules/bootstrap/scss/mixins/grid';
@import '../../../node_modules/bootstrap/scss/mixins/breakpoints';

.DataTable {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  font-size: 14px;
  color: $gray-800;
  margin: 0;

  .DataTableField {
    color: $gray-800;
   
  }
  a.DataTableField{
    color: #076399;
  }
  td[data-title="Image"]{
    @include media-breakpoint-up(lg) {
      max-width: 50px;
      text-align: center;
    }
  }
  td[data-title="Status"]{
    @include media-breakpoint-up(lg) {
      min-width: 130px;
    }
    
  }
  &__item {
    &--bold {
      font-weight: 500;;
    }

    &--actions {
      width: 80px;
      @include media-breakpoint-down(md) {
        position: absolute;
        top: 0;
        right: 0;
        width: 46px;
      }
      i {
        transform: rotate(90deg);
      }
    }
  }

  @include media-breakpoint-up(lg) {
    display: table;
    border-collapse: collapse;  
    td {
      padding:15px 10px 25px;
      background-color: $white;
      position: relative;
      &:after {
        content: "";
        display: block;
        background: #ffffff;
        height: 10px;
        position: absolute;
        left: 0;
        bottom: 9px;
        width: 100%;
        box-shadow: 0px 3px 6px rgba(0 ,0 ,0 ,0.05);
      }
      
     &:before {
        content: "";
        display: block;
        background:$body-bg;
        height: 14px;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
      }

      .user__img img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }

  th,
  td {
    font-weight: 400;
    vertical-align: middle;

    &:first-child {
      border-radius: 6px 0 0 6px;
      padding-left: 15px;
      &:after{
        border-radius: 0 0 0 6px;
      }
      @include media-breakpoint-up(xl) {
        padding-left: 20px;
      }
    }

    &:last-child {
      border-radius: 0 10px 10px 0;
      padding-right: 15px;
      &:after{
        border-radius: 0 0 6px 0;
      }
      @include media-breakpoint-up(xl) {
        padding-right: 20px;
      }
    }
  }

  th {
    padding: 12px 10px 22px;
    position: relative;
    color: $gray-800;
    background-color: $gray-100;
    font-weight: 500;
    font-size: 13px;
    
    &:before {
      content: "";
      display: block;
      background:$body-bg;
      height: 14px;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
    }
    &:after {
      content: "";
      display: block;
      background:$gray-100;
      height: 10px;
      position: absolute;
      left: 0;
      bottom: 9px;
      width: 100%;
    }
    i {
      margin-right: 8px;
    }
  }

  td {
    .Avatar {
      margin-right: 10px;
    }
  }

  .user {
    display: flex;
    align-items: center;
    &__img {
      margin-right: 10px;
    }
  }

  &--sm {
    @include media-breakpoint-up(lg) {
      td {
        padding: 10px 15px;
        height: auto;
      }
    }

    th {
      padding: 7px 15px;
    }
  }

  @include media-breakpoint-down(md) {
    &:not(.history-table) {
      display: block;

      thead {
        display: none;
      }

      tbody,
      tr,
      th {
        display: block;
        width: 100%;
      }

      td:not(.DataTable__item--actions) {
        padding: 6px 15px;
        display: flex;
        width: 100%;

        &:before {
          position: relative;
          top: 0;
          left: 0;
          content: attr(data-title);
          font-weight: 500;
          margin-right: 10px;
          min-width: 115px;
          max-width: 115px;
          color: $gray-700;
          display: inline-block;
        }

        .user__img {
          display: none;
        }
      }

      tr {
        padding: 13px 0;
        position: relative;
      }
    }
  }

  .status {
    padding: 3px 13px 4px 13px;
    font-size: 11px;
    border-radius: 10px;
    cursor: pointer;
    &.active {
      background-color: rgba(1, 161, 183,0.2);
      color: #01A1B7;
    }
    &.cancelled{
      background-color: rgba(#E10000, .2);
      color: #E10000;
    }
    &.inactive {
      background-color: rgba(#4b4a4a, .2);
      color:#4b4a4a;
    }
    &.pending{
      background-color:rgba(#ECCA1A, .2);
      color:#968908;
    }
    &.completed{
      background-color:rgba(#01B753, .2);
      color: #01B753;
    }
  }
}
