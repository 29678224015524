@import '../../styles/settings';
.sidemenu {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 270px;
  position: sticky;
  top: 74px;
  background: $white;
  height: calc(100vh - 74px);
  overflow: auto;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);

  @media only screen and (max-width: 991.98px) {
    position: fixed;
    top: 0;
    left: -290px;
    z-index: 101;
    transition: left 0.5s ease-in-out;
    border-right: none;
    width: 100%;
    height: 100%;

    .menu {
      padding: 0;
    }
  }

  &__close {
    position: fixed;
    top: 0;
    left: -290px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: $primary;
    border-left: 1px solid rgba($white, 0.06);

    .close {
      font-size: 14px;
      color: $white;
      opacity: 1;
      text-shadow: none;
    }

    &.active {
      left: 270px;
    }
  }

  &.active {
    left: 0;
  }
}

.menu {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 1rem;
  position: relative;
  overflow-x: hidden;
  padding: rem(15) 0;

  &__icon {
    font-size: rem(20);
    margin-right: 1rem;
    min-width: 23px;
    color: #000!important;
    &.icon-lead,&.icon-Payment,&.icon-Payout{
      font-size: 25px;
    }
    
  }

  &__item {
    margin: 10px 0;
  }

  &__link {
    text-decoration: none;
    color: #4E4D4D;
    display: flex;
    align-items: center;
    padding: rem(13) rem(29);
    line-height: 1;
    font-size: 16px;
    position: relative;
    border-left: 3px solid $white;
    cursor: pointer;
    &:hover {
      color: #4E4D4D;
      font-weight: 500;
    }

    &:focus {
      outline: none;
    }
  }

  &__item {
    &--active {
      .menu {
        &__link {
          background-color: lighten($primary, 50.20);
          border-color: $primary;
          color: #4E4D4D;
          font-weight: 500;
        }
      }
      .submenu {
        height: auto;
        opacity: 1;
        transition: height 0.5s ease-in-out;
      }

      .sidemenu-dropdown-toggle {
        transform: rotate(-180deg);
      }
    }
  }
}

.submenu {
  list-style: none;
  padding: 0;
  margin: 0;
  padding-left: rem(63);
  height: 0;
  opacity: 0;
  transition: height 0.5s ease-in-out;

  &__link {
    color: $gray-800;
    padding: rem(14) 0;
    line-height: 1;
    font-size: 15px;
    display: inline-block;
    width: 100%;
    i{
      html[dir="ltr"] &{
        margin-right: rem(10);
      }
      html[dir="rtl"] &{
        margin-left: rem(10);
      }
    }
    &:hover {
      color: #e10000;
    }
    &--active{
      color: #e10000;
    }
  }
}

.sidemenu-dropdown-toggle {
  white-space: nowrap;
  transition: transform 0.5s ease-in-out;
  position: absolute;
  top: 50%;
  right: 19px;
  display: inline-flex;
  margin-top: -3px;

  &:after {
    font-family: 'icomoon' !important;
    content: '\e902';
    font-size: 10px;
  }
}

.black-drop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 100;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;

  &.active {
    opacity: 1;
    visibility: visible;
  }
}
