@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins/grid';
@import '../../../node_modules/bootstrap/scss/mixins/breakpoints';

@import '../../styles/settings';

.table-toolbar {
  padding-bottom: rem(20);
  position: relative;

  &__row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
  }

  &__left {
    font-size: 1rem;
    @include make-col-ready();
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    display: flex;
    align-items: baseline;
    .title {
      display: flex;
      flex-direction: column;
      h5 {
        margin: 0;
        font-weight: 400;
        line-height: normal;
      }
      span {
        font-size: 0.8125em;
        color: $gray-700;
      }
    }
    .labelled-dropdown {
      padding-left: 2.1875rem;
      @include media-breakpoint-down(sm) {
         margin-left: auto;
      }
    }
  }
  &__right {
    @include make-col-ready();
    flex: 0 0 auto;
    width: auto;
    max-width: none;
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    .filter {
      .dropdown__item-selected {
        color: $primary;
        text-transform: uppercase;
      }
    }
  }
  &__pendingCount {
    padding: 10px 22px;
    margin-bottom: 6px;
    font-size: 0.75em;
    border-radius: 4px;
    color: $primary;
    border: 1px solid rgba($primary, 0.1);
    background-color: rgba($primary, 0.1);
  }
}
