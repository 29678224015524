@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/grid';
@import '~bootstrap/scss/mixins/breakpoints';

@import '../../../styles/settings';

.PermissiosAccordion {
  &__item {
    & + .PermissiosAccordion__item {
      border-top: 1px solid $border-color;
    }
    
    &--border {
      border: 1px solid $border-color;
    }
    &--boxshadow {
      box-shadow: 0 5px 5px rgba(0, 0, 0, 0.04);
    }
    &--curve {
      border-radius: 15px;
    }
    &--padding {
      padding: 0.125rem 0.9375rem !important;
    }
  }
  &__heading {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    cursor: pointer;
    padding: rem(14) 0;

    &--lg {
      padding: 2.125rem 1.9375rem !important;
      @include media-breakpoint-down(md) {
        padding: 1.125rem 1rem !important;
      }
    }
    &--md {
      padding: rem(29) rem(24) !important;
      @include media-breakpoint-down(md) {
        padding: rem(20) rem(16) !important;
      }
    }
    &--padding-left-md {
      html[dir='ltr'] & {
        padding-left: rem(17);
      }
      html[dir='rtl'] & {
        padding-right: rem(17);
      }
    }
    &-left {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
      @include media-breakpoint-down(sm) {
        flex: 0 0 100%;
        max-width: 100%;
      }

      .checkbox__label {
        top: 4px;
      }
    }
    &-right {
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
      display: flex;
      justify-content: flex-end;
      @include media-breakpoint-down(sm) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    &--remove-margin-left {
      flex-grow: 1;
      html[dir='rtl'] & {
        margin-right: 0;
      }
      html[dir='ltr'] & {
        margin-left: 0;
      }
    }
  }
  &__title {
    margin: 0;
    font-weight: 400;
    font-size: rem(15) !important;
    &--bold {
      font-weight: 500;
    }
    text-transform: capitalize;

    &--level-xs {
      font-size: 0.75rem !important;
    }
    &--level-sm {
      font-size: rem(15) !important;
    }
    &--level-lg {
      font-size: 1.2rem !important;
    }
  }
  &__icon {
    font-size: 0.5rem;
    color: $gray-400;
    width: 32px;
    height: 32px;
    line-height: 32px !important;
    text-align: center;
    &--font-md {
      font-size: rem(12);
    }
  }
  &__collapse {
    display: none;
    border-radius: 6px;
    background: #fff;
    padding: 6px rem(20);
    &--in {
      display: block;
    }

    & > .checkbox-outer {
      padding: 8px 0;
    }
  }
  &__body {
    padding: rem(22);
    padding-top: 0;
    color: $gray-700;
    line-height: 23px;
    @include media-breakpoint-down(md) {
      padding: 1rem;
      padding-top: 0;
    }
  }
  .data {
    display: flex;
    flex-wrap: wrap;
    font-size: 1rem;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    margin-left: -10px;
    margin-right: -10px;
    @include media-breakpoint-up(md) {
      justify-content: flex-end;
    }
    &__item {
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
      font-size: 0.9375em;
      padding-left: 10px;
      padding-right: 10px;
      flex-direction: column;
      @include media-breakpoint-down(md) {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
      }
      @include media-breakpoint-down(sm) {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
        margin-top: 8px;
      }
      &--font-lg {
        font-size: rem(18);
      }
      &--font-md {
        font-size: rem(16);
      }
      &--align-center {
        align-items: center;
      }
      &-type {
        color: $gray-400;
        font-size: 0.875em;
        &--inline {
          display: inline-block;
        }
      }
    }
  }
  .custom__label {
    margin: 0;
  }
}

.uploadDocument .AccordionCard__heading-left {
  flex: 0 0 83.33333% !important;
  max-width: 83.33333% !important;
}

.uploadDocument .AccordionCard__heading-right {
  flex: 0 0 16.66667% !important;
  max-width: 16.66667% !important;
}
