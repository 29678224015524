@import '../../styles/settings';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.tabs-header{
    @include media-breakpoint-down(md){
      background-color: $white;
    }
        .nav-item {
            font-size: 12px;
            color: $gray-800;
            border: none !important;
            background-color: transparent !important;
            padding-left: 0;
            border-bottom: solid 2px transparent;
            padding-right: 0;
            margin-right: 45px;
            margin-bottom: 0;
            @include media-breakpoint-down(md){
             width: 50%;
             margin-right: rem(24);
             text-align: center;
            //  font-size: 16px;
            }
            &:hover{
                border: 0;
            }
            &.active{
                color: $primary !important;
                font-weight: $font-weight-semibold;
                border: 0;
                border-bottom: solid 2px $primary !important;
                @include media-breakpoint-down(md){
                 color: $black;
                }
            }
        }
       .count{
           font-size: 14px;
       }
}

.ads-item{
    border-radius: 7px;
    figure{
        width: 70px;
        height: 70px;
        border-radius: 5px;
           @include media-breakpoint-down(md){
            width: 55px;
            height: 55px;
           }

    }
    figcaption{
        width: 82%;
        @include media-breakpoint-down(md){
             width: 80%;
        }
    }
    .status{
        padding: 5px 8px;
        border-radius: 25px;
        &.complete{
            background-color: #ccf1dd;
        }
    }
    .views-block{
        i{
            padding-right: 4px;
            &:before{
                vertical-align: middle;
                color: #CCCCCC;
            }
        }
    }
    .filter{
           right: 13px;
           top: 18px;
           cursor: pointer;
           z-index: 999;
           button{
               background: transparent;
           padding: 0;
           }
    }
}

#user-tabContent{
    @include media-breakpoint-up(lg){
        padding-left: rem(99);
    }
}

.overflow-y-hidden{
    overflow-y: hidden;
}

.invoice-pannel{
	// &-boxes{
	// 	background-color: #FFD3D3;
	// }
	@include media-breakpoint-down(sm) { 
		.width-row{
			overflow: auto;
			white-space: nowrap;
			display: flex;
			flex-wrap: nowrap;
		}
		.panel-box1{
			max-width: 175px;
		}
	}
	&-search{
		.search-fields{
			.form-control{
				height: 35px !important;
				padding: 9px;
			}
			.first-width{
				width: 140px;
			}
			.second-width{
				width: 210px;
			}
			.datefield{
				.date-in {
					position: absolute;
					top: 0;
					right: 0;
					display: flex;
					height: 100%;
					align-items: center;
					padding: 0 10px;
					font-size: 16px;
				}
				.form-control{
					padding-right: 32px;
				}
			}
			.search-btn{
				padding: 5px 15px;
				font-size: 14px;
			          }
		          }
		     }
	&-listing{
		.invoice-heads{
			border: 1px solid #ECECEC;
			padding: 10px 15px;
			margin-bottom: 8px;
            background-color: #f3f4f6;
		}
		.invoice-row {
			padding: 27px 15px;
			box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
			margin-bottom: 12px;
			@include media-breakpoint-down(sm) { 
				padding: 20px 15px;
				border-radius: 12px !important;
				p{
					word-break: break-all;
				}
				.labelin{
					color: $input-placeholder-color;
					font-size: 13px;
					padding-bottom: 2px;
				}
				.price{
					h6{
						color: $gray-900;
					}
				}
				.status{
					span{
						background-color: transparent;
						color: $gray-700 !important;
						font-size: 14px !important;
    					padding: 0 !important;
					}
				}
			}
			.inv-id {
				span{
				padding-right: 5px;
				i{
					font-size: 17px;
				}
				}
			}
			.status-completed{
				background-color: $success;
				padding: 4px 7px;
			}
			.status-progress{
				background-color: $yellow;
				padding: 4px 7px;
			}
		}
	}
}
.invoice-table{
	@include media-breakpoint-up(md) {
		.inv-table-id-2{
			display: none;
		}
	
	 }
	
	@media (max-width: 767.98px) { 
		.inv-table-id{
			display: none;
		}
		margin: 0 -15px;
		padding: 0px 5px;
	h5{
		font-size: 16px;
	}
	p{
		font-size: 14px !important;
	}
	table{
		th , td{
		font-size: 14px !important;
		}
	}
}
}