@import 'custom/custom-variables';
@import '~bootstrap';
@import 'settings';
@import 'icomoon';
@import 'font-face';
@import 'react-redux-toastr/src/styles/index';

html {
  font-size: 16px;

  @include media-breakpoint-down(md) {
    font-size: 15px !important;
  }
}

body {
  line-height: 1.6;
  background-color: $body-bg;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  color: $gray-900;
  font-weight: 500;
}

h1,
.h1 {
  font-size: 3.25rem !important;

  @include media-breakpoint-down(md) {
    font-size: 2.75rem !important;
  }
}

h2,
.h2 {
  font-size: 2.75rem !important;

  @include media-breakpoint-down(md) {
    font-size: 2.25rem !important;
  }
}

h3,
.h3 {
  font-size: rem(32) !important;
  line-height: rem(32);

  @include media-breakpoint-down(md) {
    font-size: rem(28) !important;
    line-height: rem(28);
  }
}

h4,
.h4 {
  font-size: rem(28) !important;
  line-height: rem(28);

  @include media-breakpoint-down(md) {
    font-size: rem(24) !important;
    line-height: rem(24);
  }
}

h5,
.h5 {
  font-size: rem(24) !important;
  line-height: rem(24);
}

h6,
.h6 {
  font-size: rem(18) !important;
  line-height: rem(18);
}

a,
button {
  &:hover,
  &:focus {
    outline: none !important;
    text-decoration: none !important;
  }
}

.content-wrapper {
  display: flex;
}

.outer {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: calc(100vh - 74px);
  padding: rem(65) rem(35) rem(65) rem(65);

  @include media-breakpoint-up(lg) {
    max-width: calc(100% - 270px);
  }
  @include media-breakpoint-down(lg) {
    padding: rem(40) rem(25);
  }
  @include media-breakpoint-down(md) {
    padding: rem(40) 15px;
  }
}

.container {
  max-width: 795px !important;
  &--lg {
    max-width: 1000px !important;
  }
}

.font-weight-medium {
  font-weight: 500;
}

.form-centered {
  max-width: 850px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

.submitButton {
  &--topgap {
    margin-top: 3rem;
  }
}

.gutter {
  &-6 {
    margin-left: -6px !important;
    margin-right: -6px !important;

    & > [class^='col'] {
      padding-left: 6px;
      padding-right: 6px;
    }
  }
  &-8 {
    margin-left: -8px !important;
    margin-right: -8px !important;

    & > [class^='col'] {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
  &-10 {
    margin-left: -10px !important;
    margin-right: -10px !important;

    & > [class^='col'] {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.font-large {
  font-size: 17px !important;
}

.font-base {
  font-size: rem(16) !important;
}

.font-medium {
  font-size: 15px !important;
}

.font-small {
  font-size: 14px !important;
}

.font-xsmall {
  font-size: 13px !important;
}

.font-xs {
  font-size: 12px;
}

.font-xxs {
  font-size: 11px;
}

.font-xl{
  font-size: rem(22) !important;
}

.text-medium {
  color: $gray-700;
}

.text-gray-500 {
  color: $gray-500;
}

.text-gray-800 {
  color: $gray-800;
}

.text-gray-900 {
  color: $gray-900;
}

.text-green {
  color: #48cc45;
}

.text-red {
  color: #e13333;
}

.bg-light-blue {
  background-color: #f5f8fc;
}

.border-bottom {
  border-bottom: 1px solid $border-color;
}

.border-top {
  border-top: 1px solid $border-color;
}

.icon-plus,
.icon-minus {
  width: 12px;
  height: 12px;
  position: relative;
  display: inline-flex;
  
  &:before,
  &:after {
    content: '';
    width: 12px;
    height: 2px;
    background: $primary;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: center center;
  }
  &-sm{
    width:8px;
    &:before,
    &:after {
      width:8px;
    }
  }
  &:after {
    transform: translate(-50%, -50%) rotate(90deg);
  }

  &--white {
    &:before,
    &:after {
      background: #fff;
    }
  }
}

.icon-minus {
  &:after {
    display: none;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-auto {
  overflow-x: auto;
  @include media-breakpoint-down(lg) {
    margin-right: -15px;
  }
}

.rounded-lg {
  border-radius: 30px;
}

.message_validation {
  color: red;
  font-size: 13px;
  display: inline-block;
  margin-top: 3px;
}

.select__control,
.css-yk16xz-control,
.css-1pahdxg-control {
  box-shadow: none !important;
  border-color: hsl(0, 0%, 80%) !important;
  font-size: 14px;
  height: 50px !important;

  &:hover {
    box-shadow: none !important;
    border-color: hsl(0, 0%, 80%) !important;
  }
}

.select__menu {
  font-size: 14px;
}

.redux-toastr {
  .top-center {
      width: auto;
      margin-left: 0;
      transform: translateX(-50%);

      @include media-breakpoint-down(md) {
          width: 100%;
          max-width: 650px;
      }
  }

  .toastr {
      opacity: 1;
      min-height: auto;
      padding: 12px 20px;
      color: $gray-900;
      margin: rem(20) 0;
      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
      &-icon {
        width: 16px !important;
        height: 16px !important;
      }

      &.rrt-error {
          background-color: #fff7f4;

          .rrt-left-container .toastr-icon {
              fill: #ff7043;
          }
      }

      &.rrt-success {
          background-color: #edf6f6;

          .rrt-left-container .toastr-icon {
              fill: $primary;
          }
      }

      & > div {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
      }

      .rrt-left-container,
      .rrt-right-container {
          -webkit-box-flex: 0;
          -webkit-flex: 0 0 auto;
          -moz-box-flex: 0;
          -ms-flex: 0 0 auto;
          flex: 0 0 auto;
          width: auto;
          max-width: none;
          padding-left: 5px;
          padding-right: 5px;
          position: relative;
          display: inline-flex;
          align-items: center;
      }

      .rrt-left-container {
          .rrt-holder {
              position: relative;
              width: auto;
              height: auto;
              margin: 0;
              top: 0;
              left: 0;
              display: inline-flex;
              align-items: center;
          }

          .toastr-icon {
              margin: 0;
          }
      }

      .rrt-middle-container {
          -webkit-flex-basis: 0;
          -ms-flex-preferred-size: 0;
          flex-basis: 0;
          -webkit-box-flex: 1;
          -webkit-flex-grow: 1;
          -moz-box-flex: 1;
          -ms-flex-positive: 1;
          flex-grow: 1;
          max-width: 100%;
          width: 100%;
          padding: 0;
          padding-left: 5px;
          padding-right: 5px;
          margin: 0;
          display: flex;
          font-weight: 600;

          .rrt-title {
              margin: 0;
              font-size: 15px;
              margin: 0 5px;
              font-weight: 500;
              color: $gray-900;
          }

          .rrt-text {
              font-size: 15px;
              color: $gray-900;
          }
      }

      .close-toastr {
          position: relative;
          opacity: 1;
          color: $gray-900;
          width: auto;
          font-size: 18px;
          padding: 0;
      }
  }
}

.with-tab {
  .primary-header {
    padding-bottom: 40px;
    @media only screen and (min-width: 992x) {
      min-height: rem(202);
    }
  }
  .TabNav {
    margin-top: rem(-38);
  }
}

.impact-plan {
  max-width: 445px;
}

.cursor-pointer {
  cursor: pointer;
}

.react-datepicker-popper {
  z-index: 9 !important;
  margin-top: 1px !important;
}

.disabled {
  pointer-events: none;
  opacity: 0.7;
}