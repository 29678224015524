.white-box {
  width: 100%;
  padding: 1.5625rem;
  background-color: #fff;
  border-radius: 4px;

  &--round-lg {
    border-radius: 6px;
  }

  &--boxShadow {
    box-shadow: 1px 1px 13px 0px rgba(0, 0, 0, 0.07);
  }

  &--boxShadow-lg {
    box-shadow: 1px 1px 13px 0px rgba(0, 0, 0, 0.07);
  }

  &--sm {
    padding: 0.9375rem;
  }

  &--lg {
    padding: 3rem;

    @media only screen and (max-width: 767.98px) {
      padding: 0.9375rem;
    }
  }

  &--text-center {
    text-align: center;
  }

  @media only screen and (max-width: 767.98px) {
    padding: 0.9375rem;
  }
}
