@import '../../styles/settings';

.fieldlabel {
  display: block;
  margin-bottom: 10px;
  color: $gray-900;
  font-size: 13px;
  line-height: normal;
}

.uploadfile {
  font-size: 1rem;
  display: inline-flex;
  width: 100%;
  position: relative;
  background: $white;
  &--border {
    border: 1px dashed $border-color;
  }

  &--curve {
    border-radius: 4px;
  }

  &--spacing {
    padding: 1rem;
    
  }

  &__outer {
    position: relative;
    width: 100%;
    display: flex;
  }

  input {
    display: none;
  }

  &__label {
    display: inline-flex;
    width: auto;
    background: transparent;
    border: none;
    padding: 0;
    max-width: calc(100% - 34px);
    color: $gray-700;
    cursor: pointer;
    margin: 0;
  }

  &__icon {
    display: inline-flex;
    max-width: 100px;
    img{
      max-width: 100%;
    }
    html[dir='ltr'] & {
      margin-right: rem(15);
    }

    html[dir='rtl'] & {
      margin-left: rem(15);
    }
  }

  &__text,
  &__subtext {
    display: block;
    width: 100%;
  }

  &__text {
    font-size: rem(17);
    color: $gray-900;
    line-height: normal;
  }

  &__subtext {
    font-size: 13px;
    margin-top: 2px;
    color: $gray-400;
    line-height: normal;
  }

  &__btn {
    color: $text-muted;
    border: none;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
  }

  &__filename {
    display: none;
    font-size: 15px;
    color: $gray-900;
    width: 100%;

    &-text {
      width: 100%;
    }

    &-subtext {
      display: block;
      color: $text-muted;
      margin-top: rem(5);
      font-size: 13px;
    }
    &-outer {
      line-height: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__close {
    display: inline-block;

    html[dir='ltr'] & {
      margin-left: rem(10);
    }

    html[dir='rtl'] & {
      margin-right: rem(10);
    }

    &-icon {
      font-size: 13px !important;
      color: $primary;
      font-weight: 600 !important;
      margin: 0 !important;
    }
  }

  &__progress {
    width: 100%;
    max-width: 180px;
    background: #f8f8f8;
    height: 7px;
    border-radius: 30px;
    position: relative;
    overflow: hidden;
    box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.05);
    flex: 0 0 300px;

    &-bar {
      display: inline-block;
      position: absolute;
      top: 0;
      width: 0%;
      height: 100%;
      background: rgba($primary, 0.1);
      transition: all 0.5s ease-in-out;

      html[dir='ltr'] & {
        left: 0;
      }
      html[dir='rtl'] & {
        right: 0;
      }
    }
  }
  &-element {
    & + .uploadfile-element {
      margin-top: rem(32);
    }
  }
}

.uploaded {
  .uploadfile {
    &__outer {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__btn {
      display: none;
    }

    &__filename {
      display: flex;
      align-items: center;
    }
  }
}

.add-img-file {
  width: 100%;
  min-height: 76px;
  border: 1px dashed $input-border-color;
}
