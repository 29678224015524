@import '../../styles/settings';

.ClickLink {
  text-decoration: none;
  color: $primary;
  font-size: 0.875rem;
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  &:hover,
  &:focus {
    color: $primary;
    text-decoration: underline;
  }
  &--underline {
    text-decoration: underline;
  }
  &--primary {
    color: $primary;
  }
  &__witharrow {
    font-size: 1rem;
    color: $gray-900;
    i {
      margin-right: 25px;
      font-size: 0.75em;
    }
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}
