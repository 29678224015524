@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?9e4cz3');
  src:  url('fonts/icomoon.eot?9e4cz3#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?9e4cz3') format('truetype'),
    url('fonts/icomoon.woff?9e4cz3') format('woff'),
    url('fonts/icomoon.svg?9e4cz3#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-lead:before {
  content: "\e983";
  color: #4e4d4d;
}
.icon-download:before {
  content: "\e9da";
  color: #7e7e7e;
}
.icon-Auction:before {
  content: "\e9f4";
}
.icon-Company:before {
  content: "\f907";
}
.icon-Payment:before {
  content: "\e981";
}
.icon-Payout:before {
  content: "\e982";
}
.icon-filter:before {
  content: "\e980";
  color: #c8c8c8;
}
.icon-chat:before {
  content: "\e97f";
}
.icon-mail2 .path1:before {
  content: "\e9de";
  color: rgb(196, 196, 196);
}
.icon-mail2 .path2:before {
  content: "\e9df";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-mail2 .path3:before {
  content: "\e9e0";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-phone2 .path1:before {
  content: "\e9e1";
  color: rgb(196, 196, 196);
}
.icon-phone2 .path2:before {
  content: "\e9e2";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-gplus .path1:before {
  content: "\e9e3";
  color: rgb(196, 196, 196);
}
.icon-gplus .path2:before {
  content: "\e9e4";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-gplus .path3:before {
  content: "\e9e5";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-gplus .path4:before {
  content: "\e9e6";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-gplus .path5:before {
  content: "\e9e7";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-gplus .path6:before {
  content: "\e9e8";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-gplus .path7:before {
  content: "\e9e9";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-fb:before {
  content: "\e9ea";
  color: #3a5a99;
}
.icon-verified2 .path1:before {
  content: "\e9eb";
  color: rgb(1, 183, 83);
}
.icon-verified2 .path2:before {
  content: "\e9ec";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-heart-filled:before {
  content: "\e9a4";
}
.icon-star-filled-2:before {
  content: "\e9c1";
}
.icon-star-outline-2:before {
  content: "\e9c2";
}
.icon-visible:before {
  content: "\e9c6";
}
.icon-linkedin2:before {
  content: "\eaca";
}
.icon-search:before {
  content: "\e97e";
}
.icon-briefcase:before {
  content: "\e97b";
}
.icon-calendar-2:before {
  content: "\e97c";
}
.icon-package:before {
  content: "\e97d";
}
.icon-Roles:before {
  content: "\e96f";
}
.icon-Subcategory:before {
  content: "\e970";
}
.icon-Subscription-Plan:before {
  content: "\e971";
}
.icon-User:before {
  content: "\e972";
}
.icon-Banner-Management:before {
  content: "\e973";
}
.icon-Admin-User:before {
  content: "\e974";
}
.icon-Brand:before {
  content: "\e975";
}
.icon-Category:before {
  content: "\e976";
}
.icon-Payments:before {
  content: "\e977";
}
.icon-Dashboard:before {
  content: "\e978";
  color: #e10000;
}
.icon-Permission:before {
  content: "\e979";
}
.icon-Products:before {
  content: "\e97a";
}
.icon-copy:before {
  content: "\e96e";
}
.icon-close:before {
  content: "\e967";
}
.icon-waiting .path1:before {
  content: "\e962";
  color: rgb(157, 157, 157);
  opacity: 0.0952;
}
.icon-waiting .path2:before {
  content: "\e963";
  margin-left: -1em;
  color: rgb(157, 157, 157);
}
.icon-waiting .path3:before {
  content: "\e964";
  margin-left: -1em;
  color: rgb(157, 157, 157);
}
.icon-waiting .path4:before {
  content: "\e965";
  margin-left: -1em;
  color: rgb(157, 157, 157);
}
.icon-waiting .path5:before {
  content: "\e966";
  margin-left: -1em;
  color: rgb(157, 157, 157);
}
.icon-calendar-clock:before {
  content: "\e961";
  color: #028282;
}
.icon-branch:before {
  content: "\e960";
  color: #969696;
}
.icon-location:before {
  content: "\e96a";
  color: #969696;
}
.icon-direction:before {
  content: "\e96b";
  color: #969696;
}
.icon-document .path1:before {
  content: "\e95a";
  color: rgb(2, 130, 130);
}
.icon-document .path2:before {
  content: "\e95b";
  margin-left: -1.3076171875em;
  color: rgb(255, 255, 255);
}
.icon-document .path3:before {
  content: "\e95c";
  margin-left: -1.3076171875em;
  color: rgb(176, 186, 192);
  opacity: 0.3596;
}
.icon-document .path4:before {
  content: "\e95d";
  margin-left: -1.3076171875em;
  color: rgb(198, 218, 217);
}
.icon-document .path5:before {
  content: "\e95e";
  margin-left: -1.3076171875em;
  color: rgb(16, 136, 136);
}
.icon-error:before {
  content: "\e95f";
  color: #ff7043;
}
.icon-failed .path1:before {
  content: "\e950";
  color: rgb(230, 92, 92);
}
.icon-failed .path2:before {
  content: "\e951";
  margin-left: -1em;
  color: rgb(230, 92, 92);
  opacity: 0.1031;
}
.icon-failed .path3:before {
  content: "\e952";
  margin-left: -1em;
  color: rgb(230, 92, 92);
}
.icon-failed .path4:before {
  content: "\e953";
  margin-left: -1em;
  color: rgb(230, 92, 92);
}
.icon-failed .path5:before {
  content: "\e954";
  margin-left: -1em;
  color: rgb(230, 92, 92);
}
.icon-passed .path1:before {
  content: "\e955";
  color: rgb(2, 130, 130);
  opacity: 0.0965;
}
.icon-passed .path2:before {
  content: "\e956";
  margin-left: -1em;
  color: rgb(2, 130, 130);
}
.icon-passed .path3:before {
  content: "\e957";
  margin-left: -1em;
  color: rgb(2, 130, 130);
}
.icon-passed .path4:before {
  content: "\e958";
  margin-left: -1em;
  color: rgb(2, 130, 130);
}
.icon-passed .path5:before {
  content: "\e959";
  margin-left: -1em;
  color: rgb(2, 130, 130);
}
.icon-play-btn .path1:before {
  content: "\e94b";
  color: rgb(2, 130, 130);
}
.icon-play-btn .path2:before {
  content: "\e94d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-success-circle .path1:before {
  content: "\e94e";
  color: rgb(2, 128, 128);
}
.icon-success-circle .path2:before {
  content: "\e94f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-padlock:before {
  content: "\e94c";
}
.icon-success:before {
  content: "\e944";
}
.icon-pdf .path1:before {
  content: "\e945";
  color: rgb(208, 226, 224);
}
.icon-pdf .path2:before {
  content: "\e946";
  margin-left: -0.9228515625em;
  color: rgb(7, 132, 132);
}
.icon-pdf .path3:before {
  content: "\e947";
  margin-left: -0.9228515625em;
  color: rgb(7, 132, 132);
}
.icon-pdf .path4:before {
  content: "\e948";
  margin-left: -0.9228515625em;
  color: rgb(7, 132, 132);
}
.icon-pdf .path5:before {
  content: "\e949";
  margin-left: -0.9228515625em;
  color: rgb(7, 132, 132);
}
.icon-notification-outline:before {
  content: "\e941";
  color: #8d8d8d;
}
.icon-notification-fill:before {
  content: "\e942";
  color: #fff;
}
.icon-logout:before {
  content: "\e943";
  color: #fff;
}
.icon-file-upload .path1:before {
  content: "\e92b";
  color: rgb(26, 114, 106);
  opacity: 0.2038;
}
.icon-file-upload .path2:before {
  content: "\e92c";
  margin-left: -0.865234375em;
  color: rgb(2, 130, 130);
}
.icon-file-upload .path3:before {
  content: "\e92d";
  margin-left: -0.865234375em;
  color: rgb(2, 130, 130);
}
.icon-file-upload .path4:before {
  content: "\e92e";
  margin-left: -0.865234375em;
  color: rgb(26, 114, 106);
}
.icon-file-upload .path5:before {
  content: "\e92f";
  margin-left: -0.865234375em;
  color: rgb(26, 114, 106);
}
.icon-file-upload .path6:before {
  content: "\e930";
  margin-left: -0.865234375em;
  color: rgb(26, 114, 106);
}
.icon-file-upload-done .path1:before {
  content: "\e931";
  color: rgb(26, 114, 106);
  opacity: 0.2038;
}
.icon-file-upload-done .path2:before {
  content: "\e932";
  margin-left: -0.865234375em;
  color: rgb(255, 255, 255);
}
.icon-file-upload-done .path3:before {
  content: "\e933";
  margin-left: -0.865234375em;
  color: rgb(2, 130, 130);
}
.icon-file-upload-done .path4:before {
  content: "\e934";
  margin-left: -0.865234375em;
  color: rgb(26, 114, 106);
}
.icon-file-upload-done .path5:before {
  content: "\e935";
  margin-left: -0.865234375em;
  color: rgb(26, 114, 106);
}
.icon-file-upload-done .path6:before {
  content: "\e936";
  margin-left: -0.865234375em;
  color: rgb(26, 114, 106);
}
.icon-file-upload-done .path7:before {
  content: "\e937";
  margin-left: -0.865234375em;
  color: rgb(2, 130, 130);
}
.icon-file-upload-done .path8:before {
  content: "\e938";
  margin-left: -0.865234375em;
  color: rgb(255, 255, 255);
}
.icon-refer-friend .path1:before {
  content: "\e939";
  color: rgb(26, 114, 106);
  opacity: 0.2020;
}
.icon-refer-friend .path2:before {
  content: "\e93a";
  margin-left: -1em;
  color: rgb(2, 130, 130);
}
.icon-support .path1:before {
  content: "\e93b";
  color: rgb(208, 226, 224);
}
.icon-support .path2:before {
  content: "\e93c";
  margin-left: -1em;
  color: rgb(2, 130, 130);
}
.icon-warning .path1:before {
  content: "\e93d";
  color: rgb(19, 177, 187);
}
.icon-warning .path2:before {
  content: "\e93e";
  margin-left: -1.205078125em;
  color: rgb(204, 222, 221);
}
.icon-warning .path3:before {
  content: "\e93f";
  margin-left: -1.205078125em;
  color: rgb(255, 255, 255);
}
.icon-warning .path4:before {
  content: "\e940";
  margin-left: -1.205078125em;
  color: rgb(26, 114, 106);
}
.icon-choose-payment-method .path1:before {
  content: "\e914";
  color: rgb(2, 128, 128);
  opacity: 0.2;
}
.icon-choose-payment-method .path2:before {
  content: "\e916";
  margin-left: -0.998046875em;
  color: rgb(2, 128, 128);
  opacity: 0.2;
}
.icon-choose-payment-method .path3:before {
  content: "\e917";
  margin-left: -0.998046875em;
  color: rgb(2, 128, 128);
  opacity: 0.2;
}
.icon-choose-payment-method .path4:before {
  content: "\e918";
  margin-left: -0.998046875em;
  color: rgb(2, 128, 128);
}
.icon-choose-payment-method .path5:before {
  content: "\e919";
  margin-left: -0.998046875em;
  color: rgb(2, 128, 128);
}
.icon-choose-payment-plan .path1:before {
  content: "\e91a";
  color: rgb(2, 128, 128);
  opacity: 0.2;
}
.icon-choose-payment-plan .path2:before {
  content: "\e91b";
  margin-left: -1em;
  color: rgb(2, 128, 128);
}
.icon-choose-payment-plan .path3:before {
  content: "\e91c";
  margin-left: -1em;
  color: rgb(2, 128, 128);
}
.icon-choose-payment-plan .path4:before {
  content: "\e91d";
  margin-left: -1em;
  color: rgb(2, 128, 128);
  opacity: 0.2;
}
.icon-course-type .path1:before {
  content: "\e91e";
  color: rgb(2, 128, 128);
  opacity: 0.2;
}
.icon-course-type .path2:before {
  content: "\e91f";
  margin-left: -1em;
  color: rgb(2, 128, 128);
}
.icon-course-type .path3:before {
  content: "\e920";
  margin-left: -1em;
  color: rgb(2, 128, 128);
  opacity: 0.2;
}
.icon-pay-at-edc:before {
  content: "\e921";
  color: #028080;
}
.icon-payonline:before {
  content: "\e922";
  color: #028080;
}
.icon-personal-details .path1:before {
  content: "\e923";
  color: rgb(2, 128, 128);
  opacity: 0.2;
}
.icon-personal-details .path2:before {
  content: "\e924";
  margin-left: -0.7802734375em;
  color: rgb(2, 128, 128);
  opacity: 0.2;
}
.icon-personal-details .path3:before {
  content: "\e925";
  margin-left: -0.7802734375em;
  color: rgb(2, 128, 128);
  opacity: 0.2;
}
.icon-personal-details .path4:before {
  content: "\e926";
  margin-left: -0.7802734375em;
  color: rgb(2, 128, 128);
}
.icon-promocode .path1:before {
  content: "\e927";
  color: rgb(2, 128, 128);
}
.icon-promocode .path2:before {
  content: "\e928";
  margin-left: -1em;
  color: rgb(2, 128, 128);
  opacity: 0.2;
}
.icon-tick:before {
  content: "\e929";
  color: #028080;
}
.icon-envelope-fill:before {
  content: "\e92a";
}
.icon-cancel:before {
  content: "\e913";
}
.icon-arrow-right:before {
  content: "\e900";
}
.icon-arrow-left:before {
  content: "\e901";
}
.icon-arrow-down:before {
  content: "\e902";
}
.icon-call-us:before {
  content: "\e903";
}
.icon-faq:before {
  content: "\e904";
}
.icon-live-chat:before {
  content: "\e905";
}
.icon-pointer1:before {
  content: "\e906";
}
.icon-tweet-us:before {
  content: "\e907";
}
.icon-envelop-outline:before {
  content: "\e908";
}
.icon-landline:before {
  content: "\e909";
}
.icon-pointer:before {
  content: "\e90a";
}
.icon-app-store:before {
  content: "\e90b";
}
.icon-play-store:before {
  content: "\e90c";
}
.icon-youtube:before {
  content: "\e90d";
}
.icon-facebook-logo:before {
  content: "\e90e";
}
.icon-instagram:before {
  content: "\e90f";
}
.icon-twitter:before {
  content: "\e910";
}
.icon-menu:before {
  content: "\e912";
}
.icon-user:before {
  content: "\e911";
}
.icon-calendar:before {
  content: "\e915";
}
.icon-print:before {
  content: "\e94a";
}
.icon-placeholder-filled-point:before {
  content: "\e96d";
}
.icon-star:before {
  content: "\e969";
}
.icon-instructors:before {
  content: "\e96c";
}
.icon-ellipse-v:before {
  content: "\e968";
}
