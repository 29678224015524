.img-listing{
    figure{
        width:146px;
        height: 130px;
        overflow: hidden;
        background: #f5f5f5;
        img{
            max-width: 100%;
            height: auto;
            width: 100%;;
        }
    }
}

.export-btn{
    i{
        &::before{
            color: #fff;
        }
    }
}