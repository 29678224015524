@import '../../styles/settings';

.filter {
  display: inline-flex;
  position: relative;
  &-dropdown {
    list-style: none;
    margin: 0;
    padding: rem(10) rem(20);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    display: inline-block;
    border-radius: 5px;
    position: absolute;
    bottom: -15px;
    transform: translateY(100%);
    z-index: 99;
    background: #fff;
    min-width: 245px;
    right: 0;
    &--right {
      right: 0;
    }
    &--center {
      left: 50%;
      transform: translate(-50%, 100%);
    }
    &-button {
      color: $primary;
      text-transform: uppercase;
      font-size: 14px;
      display: inline-flex;
      align-items: center;
      &__icon {
        font-size: 7px;
        margin-left: 8px;
      }
    }
  }
  &-accordion {
    &__head {
      padding: rem(11) 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &-left {
        color: $gray-900;
        font-size: 14px;
      }
      &-right {
        [class^='icon-'] {
          &:before,
          &:after {
            background-color: $gray-400;
          }
        }
      }
    }
    &__listing {
      list-style: none;
      padding: 0;
      margin: 0;
      font-size: 13px;
      color: $gray-700;
      margin-bottom: rem(20);
      li {
        line-height: 18px;
        margin: 9px 0;
        cursor: pointer;
        &:hover {
          color: $primary;
        }
      }
    }
    &__item  + .filter-accordion__item {
      border-top: 1px solid $border-color;
    }
  }
  &__item-remove {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #d2d2d2;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 8px;
    border: none;
    color: #000;
    margin-left: 5px;
    &:focus {
      outline: none;
    }
  }
  &-search {
    display: flex;
    align-items: center;
    // border-bottom: 1px solid $border-color;
    margin-bottom: rem(14);
    &__button {
      padding: 0;
      background-color: transparent;
      border: none;
      font-size: 14px;
      color: #c0bfbf;
      display: flex;
      cursor: pointer;
      &:focus {
        outline: none;
      }
    }
    &__input {
      font-size: 14px;
      border: none;
      outline: none;
      font-family: inherit;
      padding: 4px 10px;
      &::-webkit-input-placeholder {
        color: #c0bfbf;
        font-family: inherit;
      }
      &::-moz-placeholder {
        color: #c0bfbf;
        font-family: inherit;
      }
      &:-ms-input-placeholder {
        color: #c0bfbf;
        font-family: inherit;
      }
      &:-moz-placeholder {
        color: #c0bfbf;
        font-family: inherit;
      }
      &:focus {
        outline: none;
      }
    }
  }
}

.search-filter-dropdown {
  bottom: 25px;
  width: 30%;
}
.filter-search .Input {
  width: 100%;
  margin-bottom: 0rem;
}

.filter-search .basic-single .select__control {
  padding: 0;
}

.filter-search .Input__field {
  padding: rem(10) rem(30) rem(10) rem(8);
}
.filter-search .Input__field--icon + i {
  position: absolute;
  bottom: 10px;
  font-size: rem(16);
  color: #868686;
  right: 10px;
}

.filter-dropdown .icon-plus {
  margin-right: 0px !important;
}

.dropdown .badge {
  position: absolute;
  top: -15px;
  left: -15px;
  background: $primary;
  border-radius: 50%;
  color: white;
  font-size: 11px;
  width: 20px;
  height: 20px;
  line-height: 15px;
}
