@import '../../styles/settings';

.Header {
  padding: 7px 30px;
  background-color: $primary;
  position: sticky;
  top: 0;
  z-index: 1;
  @media only screen and (max-width: 1199.98px) {
    padding: 7px 15px;
  }
  &Row {
    display: flex;
    align-items: center;
    font-size: 1rem;
    margin-left: -15px;
    margin-right: -15px;
  }

  &__item {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }

  &__menu-toggle {
    width: 55px;
    height: 18px;
    position: relative;
    display: none;
    align-items: center;
    cursor: pointer;
    padding: 0 15px;
    @media only screen and (max-width: 991.98px) {
      display: flex;
    }
  }

  &__toggle-bar {
    width: 25px;
    height: 2px;
    background: $white;
    position: relative;
    display: inline-block;
    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 25px;
      height: 2px;
      background: $white;
      right: 0;
    }
    &:before {
      top: -7px;
    }
    &:after {
      top: 7px;
    }
  }

  &__logo {
    display: inline-flex;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
    @media only screen and (max-width: 991.98px) {
      padding-left: 5px;

      img {
        height: 50px;
      }
    }
  }

  &__options {
    flex-basis: 0;
    box-flex: 1;
    flex-grow: 1;
    max-width: 100%;
    &-row {
      display: flex;
      flex-wrap: wrap;
      margin-right: -15px;
      margin-left: -15px;
      justify-content: flex-end;
      align-items: center;
      @media only screen and (max-width: 991.98px) {
        margin-right: -10px;
        margin-left: -10px;
      }
    }
  }

  &__option-item {
    padding: 0 15px;
    display: flex;
    align-items: center;
    position: relative;
    @media only screen and (max-width: 991.98px) {
      padding: 0 10px;
    }
    button {
      background: transparent;
      border: none;
      padding: 0;
      text-transform: capitalize;
      color: $gray-700;
      font-size: .875em;
    }
  }
  &__notification {
    position: relative;
    display: inline-block;
    font-size: 1rem;
    &-icon {
      font-size: 1.3125em;
      color: $gray-700;
      text-decoration: none;
      display: flex;
      align-items: center;
    }
    &-box {
      width: 290px;
      max-height: 400px;
      overflow-y: scroll;
    }
    &-header {
      font-size: 1.25em;
      padding: 0.9375rem;
    }
    &-list {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    &-item {
      display: flex;
      padding: 0.9375rem;
      border-top: 1px solid $border-color;
    }
    &-message {
      font-size: 0.875em;
      color: $gray-700;
      margin-left: rem(12);
    }
    &-time {
      display: block;
      margin-top: 10px;
      font-size: 0.75rem;
    }
    &--active {
      .Header__notification-icon {
        &:before {
          content: '';
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: rgb(255, 132, 0);
          display: inline-block;
          position: absolute;
          top: 0;
          -webkit-box-shadow: 0 0 0 rgba(250, 140, 22, 0.4);
          -moz-box-shadow: 0 0 0 rgba(250, 140, 22, 0.4);
          box-shadow: 0 0 0 rgba(250, 140, 22, 0.4);
          animation: away 2s infinite;
          right: 3px;
        }
      }
    }
  }

  &__user {
    text-decoration: none;
    color: $gray-400;
    display: flex;
    align-items: center;
    font-size: 0.875em;
    position: relative;
    right: 0;
    cursor: pointer;
    i {
      font-size:6px;
      color: $white;
      margin-left: 15px;
    }
    &-image {
      display: inline-flex;
      align-items: center;
    }

    &-name {
      color: $white;
      max-width: 165px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-left: 12px;
      text-align: left;
      font-size: rem(15);
      font-weight: 500;
      @media only screen and (max-width: 991.98px) {
        display: none;
      }
    }

    &-item {
      list-style: none;
      margin: 0;
      padding: 4px 0.9375rem;
    }

    &-link {
      text-decoration: none;
      color: $gray-700;
      display: flex;
      align-items: center;
      font-size: 0.875em;
      &:hover {
        color: $gray-900 !important;
      }
    }
  }
} // end of Header
@keyframes away {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(250, 140, 22, 0.4);
    box-shadow: 0 0 0 0 rgba(250, 140, 22, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(250, 140, 22, 0);
    box-shadow: 0 0 0 10px rgba(250, 140, 22, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(250, 140, 22, 0);
    box-shadow: 0 0 0 0 rgba(250, 140, 22, 0);
  }
}
