@import '../../styles/settings';

.Button {
  display: inline-flex;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 2px solid $primary;
  background-color: $primary;
  color: #fff;
  padding: 1.25rem 4.25rem;
  font-size: 1rem;
  line-height: 1.125rem;
  border-radius: 4px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  font-family: inherit;
  cursor: pointer;
  
  & + .Button {
    margin-left: 20px;
  }
  &--block {
    width: 100%;
  }
  i {
    font-size: 14px;
    margin-right: 8px;
  }
  &:hover,
  &:focus {
    color: #fff;
    background: darken(desaturate($primary, 0.43), 7.45);
    outline: none !important;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }

  &--xs {
    font-size: 12px;
    line-height: 0.875rem;
    padding: 9px 17px;
    font-weight: 400;
  }

  &--sm {
    font-size: 14px;
    line-height: 14px;
    padding: 11px rem(17);
    font-weight: 500;
  }

  &--md {
    padding: rem(12) rem(57);
    @media only screen and (max-width: 991.98px) {
      padding: rem(12) rem(30);
      font-size: 14px;
    }
  }

  &--lg {
    font-size: 1.375rem;
    line-height: 1.375rem;
    padding: 1.125rem 4.5rem;
  }

  &--light {
    color: $primary;
    border: 2px solid rgba($primary, 0.1);
    background-color: rgba($primary, 0.1);
    &:hover,
    &:focus {
      color: $primary;
      background: rgba($primary, 0.15);
      outline: none;
    }
  }

  &--white {
    color: $white !important;
  }

  &--link {
    color: $gray-400;
    border: none;
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;
    font-family: $font-family-sans-serif;

    &:hover,
    &:focus {
        color: $primary;
        border: none;
        background-color: transparent;
        outline: none;
    }
  }

  &__border-none {
    border: 0 !important;
  }
  &__outline-primary {
    color: $primary;
    border-color: $primary;
    background-color: #fff;
    &:hover,
    &:focus {
      color: #fff;
      i {
        color: #fff;
        &:after,
        &:before {
          background: #fff;
        }
      }
    }
  }
  &--default {
    color: $gray-700;
    border: 2x solid $border-color;
    background-color: #f8f8f8;
    &:hover,
    &:focus {
      color: $gray-700;
      background: $border-color;
      outline: none;
    }
  }
}

.ButtonBlock {
  &--gap-sm {
    padding: 1rem 0;
  }
  &--gap-lg {
    padding: 2rem 0;
  }
  &--left {
    text-align: left;
  }
  &--center {
    text-align: center;
  }
  &--right {
    text-align: right;
  }
}
