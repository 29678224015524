@import '../../styles/settings';

.search {
  font-size: 1rem;
  display: flex;
  align-items: center;
  background-color: $white;
  border-radius: 4px;
  border: 1px solid $border-color;
  overflow: hidden;
  &__button {
    padding: 0.625rem;
    background-color: transparent;
    border: none;
    font-size: rem(20);
    color: $gray-200;
    display: flex;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }
  &__input {
    font-size: rem(15);
    border: none;
    outline: none;
    font-family: inherit;
    padding: 0.625rem 0 0.625rem 0.75rem;
    width: 207px;
    color: $gray-900;
    &::-webkit-input-placeholder {
      color: $gray-200;
      font-family: inherit;
      font-weight: 300;
    }
    &::-moz-placeholder {
      color: $gray-200;
      font-family: inherit;
      font-weight: 300;
    }
    &:-ms-input-placeholder {
      color: $gray-200;
      font-family: inherit;
      font-weight: 300;
    }
    &:-moz-placeholder {
      color: $gray-200;
      font-family: inherit;
      font-weight: 300;
    }
    &:focus {
      outline: none;
    }
  }
}
