@import '../../styles/settings';

.dropdown {
  position: relative;
  display: inline-block;
  font-size: 1rem;
  cursor: pointer;
  &__menu {
    list-style: none;
    margin: 0;
    padding: 5px 13px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    display: inline-block;
    border-radius: 5px;
    position: absolute;
    bottom: -4px;
    transform: translateY(100%);
    z-index: 99;
    background: #fff;
    right: 0;
    min-width: 100px;
    &--right {
        right: 0;
    }
    &__item,
    &-item {
      padding: 3px 0;
      white-space: nowrap;
      .dropdown__item:hover {
        color: $gray-900;
      }
      & > .dropdown__item {
        display: block;
        width: 100%;
        span,
        a {
          display: flex;
          align-items: center;
          width: 100%;
        }
      }
    }
  }
  &__hide {
    display: none;
  }
  &__img {
    width: 24px;
    height: 17px;
    border-radius: 2px;
    object-fit: cover;
    margin-right: 12px;
  }
  &__item {
    text-decoration: none;
    color: $gray-700;
    display: flex;
    align-items: center;
    font-size: 13px;
    background: transparent;
    border: none;
    padding: 0;
    text-align: left;
    line-height: 19px;
    
    .DataTableField {
      color: $gray-700 !important;
      &:hover {
        color: $gray-900 !important;
      }
    }
  }
  &--theme-filled {
    background: #fff;
    border:$border-color 1px solid;
    padding:.7rem 1rem;
    border-radius: 4px;
  }
  &--theme-primary {
    color: $primary !important;
  }
  &--theme-secondary {
    color: $gray-900 !important;
  }
  &__arrow {
    font-weight: 600;
    font-size: 0.4375em;
    margin-left: 12px;
  }
  &Action {
    & > .dropdown__item {
      padding: 7px 16px;
      border-radius: 5px;
      @media only screen and (min-width: 992px) {
        background: $gray-100;
      } 
    }
  }

  button.dropdown__item {
    cursor: pointer;
  }
}

.user__name {
  color: $primary;
  max-width: 165px;
  min-width: 115px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 12px;
  @media only screen and (max-width: 991.98px) {
    display: none;
  }
}

.labelled-dropdown {
  .label {
    color: #c2bebf;
    font-size: 0.875rem;
  }
  .dropdown__arrow {
    color: #c2bebf;
  }
  .dropdown__item-selected {
    color: $gray-900;
  }
}
