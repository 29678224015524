$bootstrap-sass-asset-helper: false !default;
$browser-context: 16; // Default
@function rem($pixels, $context: $browser-context) {
  @return #{$pixels/$context}rem;
}

$primary: #e10000;
$secondary: #ffffff;
$gray-100: #F3F4F6;
$gray-200: #afb0b0;
$gray-300: #dfdfdf;
$gray-400: #999b9c;
$gray-500: #949292;
$gray-600: #767a79;
$gray-700: #707375;
$gray-800: #4E4D4D;
$gray-900: #1E1D1D;
$border-color: #ececec;
$input-border-color: #efefef;
$text-muted: $gray-400;
$border-color: #ededed;
$white: #fff;
$hover: #0bcbd8;
$dark: #000;
$dark-100: #262626;
$body-bg: #FAFAFA;

$font-family-sans-serif: 'Circular Pro', 'Arial', 'Helvetica Neue', sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$font-family-arabic: 'Circular Pro', 'Arial', 'Helvetica Neue', sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$font-family-base: 'Circular Pro', 'Arial', 'Helvetica Neue', sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  $font-size-base: 1rem;
  $font-size-lg: 1.0625rem;
  $font-size-sm: 0.9375rem;
  $font-weight-light: 300;
  $font-weight-normal: 400;
  $font-weight-medium: 500;
  $font-weight-semibold: 600;
  $font-weight-bold: 700;
  .bg-primary {
    background-color: $primary !important;
  }
  .bg-secondary {
    background-color: $secondary !important;
  }
