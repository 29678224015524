@import '../../styles/settings';

.Popup {
  position: absolute;
  font-size: 1rem;
  -moz-box-shadow: 1px 2px 7px 0px rgba(94, 41, 41, 0.1);
  box-shadow: 1px 2px 7px 0px rgba(0, 0, 0, 0.1);
  z-index: 99;
  background-color: #fafafa;
  border-radius: 5px;
  overflow: hidden;

  &__content {
    margin: 0;
    padding: 7px 0;
    background-color: $white;
    min-width: 135px;
  }
}
